export const APP_STATE = {
  LOADING: "loading",
  READY: "ready",
  ERROR: "error"
}

export const THIS_APP = "FGF Portal";

export const TRUE_FALSE_VALUES = {
  TRUE: "true",
  FALSE: "false"
};

export const PRODUCT_TYPE = {
  PERSONAL_LOAN: "personalLoan",
  CAR_LOAN: "carLoan",
  LINE_OF_CREDIT: "lineOfCredit",
  LINE_OF_CREDIT_DRAW_DOWN: "lineOfCreditDrawDown",
};

export const THEMES = {
  default: "defaultTheme",
  rentBont: "rentBontTheme"
};

export const STATES = {
  ACT: "ACT",
  NSW: "NSW",
  NT: "NT",
  QLD: "QLD",
  SA: "SA",
  TAS: "TAS",
  VIC: "VIC",
  WA: "WA",
};

export const IDENTIFICATION_TYPES_VALUES = {
  DRIVERS_LICENCE: "DriversLicence",
  MEDICARE: "MedicareCard"
};

export const LOAN_STATUS = {
  DECLINED: "Declined",
  CREATED: "Created",
  DECLINED_IN_PROGRESS: "DeclinedInProgress",
  NOT_RETRIEVABLE: "NotRetrievable",
  NOT_FOUND: "NotFound",
  INVALID: "Invalid"
};

export const LIVING_SITUATIONS = {
  LIVE_WITH_PARTNER: "I live with my partner",
  LIVE_WITH_MY_FRIEND: "I live with friend(s)"
};

export const RESIDENTIAL_STATUS = {
  RENTING: "Renting",
  MORTGAGE: "Mortgage",
  BOARDING: "Boarding",
  HOMEOWNER: "Homeowner",
};

export const FIELD_NAMES = {
  BANK_STATEMENTS_ID: "BankStatementsId",
  LOAN_AMOUNT: "loanAmount",
  LOAN_AMOUNT_ADDITIONAL: "__clientsideonly__loanAmountAdditional", // this is used purely in the form to determine to show SACC warning as loanAmount incs prev loan
  REPAYMENT_FREQUENCY: "repaymentFrequency",
  RESIDENTIAL_STATUS: "residentialStatus",
  LOAN_TYPE: "loanType",
  MINIMUM_REPAYMENT: "minimumRepayment",
  ACCOUNT_PURPOSE: "accountPurpose",
  NEW_LOAN_AMOUNT: "newLoanAmount",
  REDRAW_AMOUNT: "__clientsideonly__redrawAmount",
  PREVIOUS_ACCOUNT_TYPE: "__clientsideonly__previousAccountType",
  ACCOUNT_ID: "accountId",
  DRAW_DOWN_AMOUNT: "drawdownAmount",
  REPAYMENT_AMOUNT: "repaymentAmount",
  PROMISE_VALUE: "promiseValue",
  PROMISE_FIRST_DATE: "promiseFirstDate",
  START_DATE: "startDate",
  PAYMENT_VALUE: "paymentValue",
  CONTRACTUAL_PAYMENT_REGULAR: "__clientsideonly__contractualPaymentRegular",
  NETBALANCE_VALUE: "__clientsideonly__netBalance",
  MINIMUM_PAYMENT_VALUE: "__clientsideonly__minimumPaymentValue",
  ACCOUNT_TYPE: "__clientsideonly__accountType",
  CATEGORY_OPTIONS: "categoryOptions",
}

export const FORM_ID_PORTAL_PL_APPLY = "portal-form-pl-apply";
export const FORM_ID_PORTAL_LOC_APPLY = "portal-form-loc-apply";
export const FORM_ID_PORTAL_CAR_LOAN_APPLY = "portal-form-cl-apply";
export const FORM_ID_PORTAL_REFINANCE = "portal-form-refinance";
export const FORM_ID_PORTAL_LOC_DRAWDOWN = "portal-form-loc-drawdown";

export const isPositionStickySupported = (() => {
  const falseFunc = () => false;
  return 'CSS' in window && (CSS.supports || falseFunc)('position', 'sticky');
})();

export const REDIRECT_DELAY_SECONDS = {
  STANDARD: 1
}

export const FREQUENCY = {
  WEEKLY: "Weekly",
  FORTNIGHTLY: "Fortnightly",
  MONTHLY: "Monthly"
}

export const PUBLIC_WEBSITE_PAGE = {
  MANAGE_REPAYMENTS: "/how-to-get-a-loan/repayments/",
  DECREASE_REPAYMENTS: "/how-to-get-a-loan/decrease-my-repayments",
};

export const INCREASE_PAYMENT_STATES = {
  PAYMENT_ENDING: "payment-ending",
  SCHEDULE_LESS_THAN_TWO: "scheduled-less-than-two",
  SCHEDULE_MORE_THAN_TWO: "scheduled-more-than-two",
  NO_PAYMENT: "no-payment",
}

export const MANAGE_MY_ARREARS_STATES = {
  ARREARS: "arrears",
  ARREARS_LESS_THAN_THREE: "arrears-less-than-three",
  ARREARS_EXPIRED_MATURITY_DATE: "arrears-expired-maturity-date",
  SHOW_CONTACT_US: "show-contact-us",
  REDIRECT_MANAGE_REPAYMENTS: "redirect-manage-repayments",
  ARREARS_LOC: "arrears-loc",
}

export const DECREASE_PAYMENT_STATES = {
  REDIRECT_MANAGE_REPAYMENTS: "redirect-manage-repayments",
  SHOW_CONTACT_US: "show-contact-us",
}

export const LOAN_RANGE = {
  SACC: {
    MAX: 2000
  },
  PERSONAL_LOAN: {
    MIN: 2050,
    MAX: 10000,
  },
  LINE_OF_CREDIT: {
    MIN: 500,
    MAX: 3000,
  },
  CAR_LOAN: {
    MIN: 5001,
    MAX: 24000,
  },
};
export const MIN_REDRAW_AMOUNT = 200;

export const OPEN_ACCOUNT_NOTES = {
  TRANSACTION_SYMBOL: "#",
  TRANSACTION_NOTE:
    "This does not reflect any pending payments being processed.",
  LOC_REPAYMENT_SYMBOL: "^",
  LOC_REPAYMENT_NOTE:
    "If you've organised a higher payment this will not be shown.",
  BALANCE_SYMBOL: "*",
  BALANCE_NOTE:
    "Your outstanding balance may not include all accrued interest or fees.",
};

export const BANNER_NAMES = {
  LOC_REDRAW_NOTIFICATION: "locRedrawMessaging",
}

export const ACCOUNT_WIDGET_DATA = {
  ARREARS: "Total Arrears Amount",
  PAYMENT_DATE: "Next Payment Due",
  REPAYMENT: "Repayment Amount",
  NETBALANCE: "Net Balance",
  APPLICATION_SUBMITTED: "Application Submitted",
  REQUESTED_AMOUNT: "Requested Amount",
  MISSED_HARDSHIP_REPAYMENTS: "Missed Hardship Repayments",
}

export const NOTIFICATION_TYPE = {
  CLIENT: "client_notifications",
  ACCOUNT: "account_notifications"
}

export const NOTIFICATION_UID = {
  1: "preferences-update",
  2: "system-maintenance",
  3: "password-reset",
  4: "change-in-information",
  5: "payment-method-changed",
  6: "service-hours-changed",
  7: "portal-functionality",
  8: "account-transaction-activity",
  9: "payment-received",
  10: "payment-due",
  11: "overdue-payment",
  12: "processing-delay",
  13: "public-holidays",
  14: "fees-changed",
  15: "late-fees",
  16: "terms-changed",
  17: "repayment-assistance",
  18: "boost-winner",
  19: "hardship-active",
  20: "hardship-application"
}

export const MONITOR_CATEGORY_A = {
  PREHARDSHIP: "PREHSHIP",
  HARDSHIP: "HARDSHIP"
};

export const MINIMUM_TOP_UP = 500;

export const COOKIES_NAME = {
  EMAIL_ADDRESS_COOKIE: "fgfpr_ea",
  CLIENT_ID_COOKIE: "fgfpr_cin"
};
