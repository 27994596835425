import styled from "styled-components";
import { palette } from "styled-theme";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  z-index: 10000000000;
  top: 0;
  right: 0;

  @media only screen and (min-width: 768px) and (max-width: 1220px) {
    width: calc(100% - 80px);
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
  }

  .message {
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 8px;
    color: ${palette("color", 27)};
  }
`;

export default Wrapper;
