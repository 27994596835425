import styled from "styled-components";
import { palette, size } from "styled-theme";

export default styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;  
  width: 100%;
  height: 60px;
  background: ${palette("color", 44)};
  position: sticky;
  top: 0;
  z-index: 1;

  @media only screen and (min-width: ${size("XM")}) {
    display: none;
  }
`;
