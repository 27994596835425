import styled from "styled-components";
import { palette, size } from "styled-theme";

export default styled.div`  

  &.show {
    .background {
      opacity: 0.6;
      z-index: 100;
    }
    
    .sidebar{
      display: block;
      left: 0;
    }
  }
  
  .footer-group {
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .sidebar{
    top: 60px;
    height: 100vh;
    background: ${palette("color", 44)};
    width: 100%;
    position: fixed;
    z-index: 100;
    left: -100%;
    transition: left 0.25s linear;

    svg {
      cursor: pointer;
    }

    .left-container {
      margin-left: 40px;
    }

    .left-container span {
      font-size: 16px;
      font-weight: bold;
      margin-right: 10px;
    }

    .left-container svg:first-child {
      margin: 0px 10px 0px 20px;
    }

    .right-container a svg,
    .right-container button svg {
      margin-right: 20px;
    }

    .right-container button {
      background: transparent;
      border: 0px;
    }

    .more-options {
      background-color: ${palette("color", 42)};
    }

    .more-options svg {
      margin-left: 40px;
      margin-top: 15px;
    }

    .more-options div:last-child {
      padding-bottom: 15px;
    }

    .more-options span {
      margin-left: 10px;
      color: ${palette("color", 16)};
    }
  }
  @media only screen and (min-width: ${size("XM")}) {
    display: none;
    
    .background, 
    .sidebar {
      display: none;
    }
    
    &.show {
      .background {
        display: none;
      }
      
      .sidebar{
        display: none;
      }
    }
  }
`;
