import React, { useCallback, useEffect, useState } from "react";
import Wrapper from "./sidebarLeftDesktop.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useSpring, animated } from "@react-spring/web";
import FairGoLogo from "../../theme/assets/fairgo-logo.svg";
import { UnStyledLink } from "../textButton";
import { PRIVATE_ROUTE } from "../../route.constants";
import Footer from "../footer/footer";
import SidebarContent from "./sidebarContent";
import theme from "../../theme/config/default";

const SidebarLeftDesktop = () => {
  const getSessionStorageValue =
    sessionStorage.getItem("expandedStatusLeft") === "false" ? false : true;
  const [isExpandedLeftNav, setIsExpandedLeftNav] = useState(
    getSessionStorageValue
  );

  const [springs, api] = useSpring(() => ({
    from: { width: isExpandedLeftNav ? 340 : 60 },
  }));

  const [springsLogo, apiLogo] = useSpring(() => ({
    from: {
      width: isExpandedLeftNav ? 180 : 40,
      height: isExpandedLeftNav ? 120 : 40,
    },
  }));

  const [springsFooter, apiFooter] = useSpring(() => ({
    from: {
      transform: isExpandedLeftNav ? "translateX(0px)" : "translateX(-250px)",
    },
  }));

  const handleExpandLeftNav = useCallback(() => {
    sessionStorage.setItem("expandedStatusLeft", "true");
    setIsExpandedLeftNav(true);
    api.start({
      from: {
        width: 60,
      },
      to: {
        width: 340,
      },
    });
    apiLogo.start({
      from: {
        width: 40,
        height: 40,
      },
      to: {
        width: 180,
        height: 120,
      },
    });
    apiFooter.start({
      from: {
        transform: "translateX(-250px)",
      },
      to: {
        transform: "translateX(0px)",
      },
    });
  }, [setIsExpandedLeftNav, apiFooter, apiLogo, api]);

  const handleCollapseLeftNav = useCallback(() => {
    sessionStorage.setItem("expandedStatusLeft", "false");
    setIsExpandedLeftNav(false);
    api.start({
      from: {
        width: 340,
      },
      to: {
        width: 60,
      },
    });
    apiLogo.start({
      from: {
        width: 180,
        height: 120,
      },
      to: {
        width: 40,
        height: 40,
      },
    });
    apiFooter.start({
      from: {
        transform: "translateX(0px)",
      },
      to: {
        transform: "translateX(-250px)",
      },
    });
  }, [setIsExpandedLeftNav, apiFooter, apiLogo, api]);

  useEffect(() => {
    if (sessionStorage.getItem("expandedStatusLeft") === null) {
      sessionStorage.setItem("expandedStatusLeft", "true");
    } else {
      return;
    }
  });

  return (
    <animated.div
      className="nav-left-desktop"
      style={{
        background: theme.palette.color[59],
        ...springs,
      }}
    >
      <Wrapper isExpandedLeftNav={isExpandedLeftNav}>
        <div className="sidebar">
          <button
            className="left-nav-expand"
            onClick={
              isExpandedLeftNav ? handleCollapseLeftNav : handleExpandLeftNav
            }
          >
            <FontAwesomeIcon icon={faBars} color={theme.palette.color[57]} size="lg" />
          </button>
          <UnStyledLink
            to={PRIVATE_ROUTE.LANDING}
            className="mid-container-desktop"
          >
            <animated.div
              className="fgf-logo-animate"
              style={{
                ...springsLogo,
              }}
            >
              <img src={FairGoLogo} alt="Fair Go Finance Desktop" />
            </animated.div>
          </UnStyledLink>
          <SidebarContent
            isExpandedLeftNav={isExpandedLeftNav}
            shouldShowSidebarContent={true}
            menuSide="left"
          />
          <animated.div style={{ ...springsFooter }} className="footer-group">
            <Footer />
          </animated.div>
        </div>
      </Wrapper>
    </animated.div>
  );
};

export default SidebarLeftDesktop;
