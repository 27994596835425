import styled from "styled-components";
import { palette, size } from "styled-theme";

export default styled.div`
  display: none;
  height: 100vh;
  width: 100%;
  background: ${palette("color", 44)};
  
  .sidebar {
    display: flex;
    flex-direction: column;
  }

  .right-nav-expand {
    background: transparent;
    width: 60px;
    border: none;
    padding: 10px;
    cursor: pointer;
    align-self: flex-end;
  }

  @media only screen and (min-width: ${size("XM")}) {
    display: block;
  }

  .link-container {
    p {
      margin-right: 10px;
    }

    svg {
      width: 25px;
    }
  }
`;
