import React from "react";
import PageWrapper from "../../containers/PageWrapper";
import Paper from "../paper";
import ContentWrapper from "../contentWrapper";
import { palette } from "styled-theme";
import styled from "styled-components";

const Wrapper = styled.div`
  h1 {
    font-size: 24px;
    line-height: 1.33;
    letter-spacing: -0.2px;
    color: ${palette("color", 27)};
    text-align: center;
    margin-bottom: 16px;
  }
  a {
    display: block;
    text-align: center;
    color: ${palette("color", 27)};
  }
`;

export const LoggedOut = () => (
  <PageWrapper
    shouldShowSidebarContent={false}
    shouldShowHeader={false}
    shouldShowMobileFooter={false}
  >
    <Paper className="page-wrapper page-wrapper-padded">
      <ContentWrapper>
        <Wrapper>
          <h1>Logged Out!</h1>
          <a href="/">Home</a>
        </Wrapper>
      </ContentWrapper>
    </Paper>
  </PageWrapper>
);
