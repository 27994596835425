import React from "react";
import Wrapper from "./footer.styles";
import { currentYear } from "../../utils/helpers/dateHelper";

const Footer = () => (
  <Wrapper>
    <div className="copyright-container">
      <p>
        © 2012-{currentYear} Fair Go Finance &#x2022; ACN 134 369 574 | ACL
        387995
      </p>
    </div>
    <div className="copyright-container-desktop">
      <p>
        © 2012-{currentYear} Fair Go Finance <br></br> ACN 134 369 574 | ACL
        387995
      </p>
    </div>
    <div className="lock-container">
      <p>
        <span>Secure &amp; Private</span> &#x2022; Your info is safe with us
      </p>
    </div>
    <div className="lock-container-desktop">
      <p>
        <span>Secure &amp; Private</span> <br></br> Your info is safe with us
      </p>
    </div>
  </Wrapper>
);

export default Footer;
