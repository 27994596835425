import { authUrl, appUrl, authClientId }  from './environmentSettings';

export const IDENTITY_CONFIG = {
    authority: authUrl,
    client_id: authClientId, //(string): Your client application's identifier as registered with the OIDC provider.
    redirect_uri: appUrl + '/signin-oidc', //The URI of your client application to receive a response from the OIDC provider.
    automaticSilentRenew: true,
    silent_redirect_uri: appUrl + '/silent-refresh.html',
    includeIdTokenInSilentRenew: true,
    response_type: "code",
    scope: "openid profile apiscope:fairgo.site.portal",
    post_logout_redirect_uri: appUrl + '/logged-out',
    loadUserInfo: true,
    login: authUrl + "/login",
    logout: authUrl + "/logout",
};

export const METADATA_OIDC = {
    issuer: authUrl,
    jwks_uri: authUrl + "/.well-known/openid-configuration/jwks",
    authorization_endpoint: authUrl + "/connect/authorize",
    token_endpoint: authUrl + "/connect/token",
    userinfo_endpoint: authUrl + "/connect/userinfo",
    end_session_endpoint: authUrl + "/connect/endsession",
    check_session_iframe: authUrl + "/connect/checksession",
    revocation_endpoint: authUrl + "/connect/revocation",
    introspection_endpoint: authUrl + "/connect/introspect"
};