import React from "react";
import styled from "styled-components";
import PageWrapper from "../PageWrapper";
import { Paper } from "../../components/paper/paper";
import { Link } from "react-router-dom";

const FourOhFourPaper = styled(Paper)`
  margin-top: 50px;

  .four-oh-four-header {
    font-size: 25px;
    text-align: center;
    font-weight: bold;
  }

  .four-oh-four-description {
    max-width: 600px;
    padding: 40px 20px 0;
    text-align: center;
    margin: 0 auto;
  }
`;

export const FourOhFour = () => {
  return (
    <PageWrapper
      shouldShowSidebarContent={false}
      shouldShowHeader={false}
      shouldShowMobileFooter={false}
    >
      <FourOhFourPaper>
        <h1 className="four-oh-four-header">
          404
        </h1>

        <p className="four-oh-four-description">
          This page does not exist, click <Link to="/">here</Link> to head to the dashboard
        </p>
      </FourOhFourPaper>
    </PageWrapper>
  );
};
