import { all, takeLatest, put } from "redux-saga/effects";
import actions from "./actions";
import { getTheme } from "../../theme/config/theme.config";

function* setInitialTheme(action) {
  const { payload } = action;
  const theme = getTheme(payload);
  yield put(actions.changeTheme(theme));
}

export const __TEST__ = {
  setInitialTheme,
};

export default function* rootSaga() {
  yield all([
    takeLatest(actions.INITIALISE_QUERY_STRING, setInitialTheme)
  ]);
}
