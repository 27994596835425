import actions from "./actions";
import { THEMES } from "../../theme/global/constants";

const initState = {
  queryString: {},
  theme: THEMES.loading
};

export default function(state = initState, action) {
  switch (action.type) {
    case actions.INITIALISE_QUERY_STRING:
      return {
        ...state,
        queryString: action.payload,
      };
    case actions.CHANGE_THEME:
      return {
        ...state,
        theme: action.payload
      };
    default:
      return state;
  }
}
