import styled from "styled-components";
import { palette, size } from "styled-theme";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  .dashboard-content-group {
    margin: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media only screen and (min-width: ${size("XM")}) {
      margin: 0 10px;
    }
  }

  .desktop-nav-left, .desktop-nav-right {
    display: none;
    height: 100vh;
    background: ${palette("color", 44)};

    @media only screen and (min-width: ${size("M")}) {
      display: block;
    }
  }

  .nav-left-desktop {
    display: none;

    @media only screen and (min-width: ${size("XM")}) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .nav-right-desktop {
    display: none;

    @media only screen and (min-width: ${size("XM")}) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;

export default Wrapper;
