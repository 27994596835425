import { cookieDomain } from "../../constants/environmentSettings";

export const oneYearFromToday = () => {
  const date = new Date();
  date.setUTCFullYear(date.getUTCFullYear() + 1);

  return date;
};

export const defaultCookiesOptions = {
  path: "/",
  expires: oneYearFromToday(),
  domain: cookieDomain,
  sameSite: "lax",
};
