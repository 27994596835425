import styled from "styled-components";
import { palette, size } from "styled-theme";

export default styled.div`
  display: none;
  height: 100vh;
  background: ${palette("color", 44)};
  width: 100%;

  .sidebar {
    display: flex;
    flex-direction: column;
  }

  .footer-group {
    position: fixed;
    bottom: 0;
    white-space: nowrap;
    overflow: hidden;
  }

  .left-nav-expand {
    background: transparent;
    border: none;
    padding: 10px;
    cursor: pointer;
    align-self: flex-start;
    width: 60px;
  }

  .mid-container-desktop {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-top: 15px;
    height: 135px;
  }

  .fgf-logo-animate {
    display: flex;
    justify-content: left;
    overflow: hidden;
  }

  .fgf-logo-animate img {
    height: 100%;
    width: 180px;
  }

  @media only screen and (min-width: ${size("XM")}) {
    display: block;
  }

  .link-container {
    svg {
      width: 25px;
    }
  }
`;
