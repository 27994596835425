import styled from "styled-components";
import { size } from "styled-theme";

export const Paper = styled.div`
  width: 100%;
  box-sizing: border-box;

  @media only screen and (min-width: ${size("XM")}) {
    width: 100%;
    padding: 0;
  }
`;

export const PaddedPaper = styled(Paper)`
  padding: 20px;
`;

export const PaddedPaperDashboard = styled(Paper)`
  padding: 0;

  .desktop-view-accounts {
    display: none;

    @media only screen and (min-width: ${size("XXM")}) {
      display: block;
    }
  }

  .mobile-view-accounts {
    display: block;

    @media only screen and (min-width: ${size("XXM")}) {
      display: none;
    }
  }
`;
