import React from "react";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import themes from "./theme/config/theme.config";
import { AuthProvider } from "./providers/authProvider";
import { AxiosAuthInterceptor } from "./components/axiosAuthInterceptor";
import useSetHashedCookies from "./hooks/useSetHashedCookies";
import { COOKIES_NAME } from "./constants/commonConstants";
import { defaultCookiesOptions } from "./utils/helpers/cookiesHelper";

export default function AppProvider({ children }) {
  const themeName = useSelector((state) => state.StartUp.theme);
  const { emailAddress, clientId } = useSelector(
    (state) => state.Client.clientData.clientDetails || {}
  );

  useSetHashedCookies(COOKIES_NAME.EMAIL_ADDRESS_COOKIE, emailAddress, defaultCookiesOptions);
  useSetHashedCookies(COOKIES_NAME.CLIENT_ID_COOKIE, clientId, defaultCookiesOptions);

  return (
    <ThemeProvider theme={themes[themeName]}>
      <AuthProvider>
        {children}
        <AxiosAuthInterceptor />
      </AuthProvider>
    </ThemeProvider>
  );
}
