import { apiManagement } from "../constants/environmentSettings";

export const BASE_URL = apiManagement;
export const GET_CLIENT_PROFILE = "/pops/api/client/profile";
export const LOAN_REASONS = "/pops/api/loanReason";
export const REAPPLY_POST = "/pops/api/Reapply";
export const REFINANCE_POST = "/pops/api/Refinance";
export const DRAWDOWN_POST = "/pops/api/Account/Drawdown";
export const getAccountTransactionsEndpoint = (accountId) => `/pops/api/Account/${accountId}/Transactions`;
export const getIncreaseRepaymentsEndpoint = (accountId) => `/pops/api/Account/${accountId}/IncreaseRepayments`;
export const getDoubleNextPaymentEndpoint = (accountId) => `/pops/api/Account/${accountId}/DoubleNextRepayment`;
export const bankTransferLogIncreaseEndpoint = (accountId) => `/pops/api/Account/${accountId}/Repayment/BankTransfer`;
export const bankTransferLogArrearsEndpoint = (accountId) => `/pops/api/Account/${accountId}/Arrears/BankTransfer`;
export const getDownloadTransactionPdfEndpoint = (accountId) => `${BASE_URL}/pops/api/Account/${accountId}/GetPdfAccountStatement`;
export const getDownloadContractPdfEndpoint = (accountId) => `${BASE_URL}/pops/api/Account/${accountId}/GetPdfAccountContract`;
export const getPayOverThreeRepaymentsEndpoint = (accountId) => `${BASE_URL}/pops/api/Account/${accountId}/Arrears/CreateThreePaymentPromise`;
export const getCreatePaymentPromiseIncreaseEndpoint = (accountId) => `pops/api/Account/${accountId}/Repayment/CreatePaymentPromise`;
export const getCreatePaymentPromiseArrearsEndpoint = (accountId) => `pops/api/Account/${accountId}/Arrears/CreatePaymentPromise`;
