import styled from "styled-components";
import { size } from "styled-theme";

const ContentWrapper = styled.div`

  max-width: ${(props) => props.maxWidth || "none"};
  margin: 0 auto;
  background-color: #fff;
  padding: ${(props) => props.hidePadding === true? 0 : "20px 0" };

  &.welcomeBanner {
    background-color: #f3f3f3;
    margin: 0px -20px;
  }

  @media only screen and (min-width: ${size("XM")}) {
  
    &.welcomeBanner {
      background-color: #fff;
    }
  }
  
`;

export default ContentWrapper;
