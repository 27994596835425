import React from "react";
import Wrapper from "./mobileHeader.styles";
import FairGoLogo from "../../theme/assets/fairgo-logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { UnStyledLink } from "../textButton";
import { PRIVATE_ROUTE } from "../../route.constants";
import theme from "../../theme/config/default";

const MobileHeader = ({ showSidebarLeft, setShowSidebarLeft, showSidebarRight, setShowSidebarRight }) => {
  const handleSetNavSideLeft = () => {
    if (showSidebarRight === true) {
      setShowSidebarRight(false);
    }
    setShowSidebarLeft(!showSidebarLeft);
  }

  const handleSetNavSideRight = () => {
    if (showSidebarLeft === true) {
      setShowSidebarLeft(false);
    }
    setShowSidebarRight(!showSidebarRight);
  }

  const handleCloseAllNav = () => {
    if (showSidebarLeft === true) {
      setShowSidebarLeft(false);
    }
    if (showSidebarRight === true) {
      setShowSidebarRight(false);
    }
  }

  return (
    <Wrapper onClick={handleCloseAllNav}>
      <div className="left-container">
        <button onClick={handleSetNavSideLeft}>
          <FontAwesomeIcon icon={faBars} color={theme.palette.color[57]} size="lg" />
        </button>
      </div>
      <UnStyledLink to={PRIVATE_ROUTE.LANDING} className="mid-container">
        <img src={FairGoLogo} alt="Fair Go Finance"/>
      </UnStyledLink>
      <div className="right-container">
        <button onClick={handleSetNavSideRight}>
          <FontAwesomeIcon icon={faEllipsisV} color={theme.palette.color[57]} size="lg" />
        </button>
      </div>
    </Wrapper>
  );
};

export default MobileHeader;
