import { useSelector } from "react-redux";
import { THEMES } from "../../theme/global/constants";
import Loader from "./index";
import React from "react";
import { APP_STATE } from "../../constants/commonConstants";

export const LoadingStubber = ({children}) => {
  const themeName = useSelector(state => state.StartUp.theme);
  const { appState } = useSelector(state => state.Client);

  if (themeName === THEMES.loading || appState === APP_STATE.LOADING) {
    return <Loader />;
  }

  return <>{children}</>;
};