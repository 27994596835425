import React, { useState } from "react";
import Wrapper from "./sidebarRightDesktop.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { useSpring, animated } from "@react-spring/web";
import SidebarContent from "./sidebarContent";
import theme from "../../theme/config/default";

const SidebarRightDesktop = ({ setShowNotifications }) => {
  const getSessionStorageValue = (sessionStorage.getItem("expandedStatusRight")) === "true" ? true : false;
  const [isExpandedRightNav, setIsExpandedRightNav] = useState(getSessionStorageValue);

  const [springs, api] = useSpring(() => ({
    from: {  width: isExpandedRightNav? 340 : 60 },
  }));

  const handleExpandRightNav = () => {
    sessionStorage.setItem("expandedStatusRight", "true");
    setIsExpandedRightNav(true);
    api.start({
      from: {
        width: 60,
      },
      to: {
        width: 340,
      },
    });
  };

  const handleCollapseRightNav = () => {
    sessionStorage.setItem("expandedStatusRight", "false");
    setIsExpandedRightNav(false);
    api.start({
      from: {
        width: 340,
      },
      to: {
        width: 60,
      },
    });
  };

  return (
    <animated.div
      className="nav-right-desktop"
      style={{
        background: theme.palette.color[59],
        ...springs,
      }}
    >
      <Wrapper>
        <div className="sidebar">
          <button
            className="right-nav-expand"
            onClick={isExpandedRightNav ? handleCollapseRightNav : handleExpandRightNav}
          >
            <FontAwesomeIcon icon={faEllipsisV} color={theme.palette.color[57]} size="lg" />
          </button>
          <SidebarContent setShowNotifications={setShowNotifications} isExpandedRightNav={isExpandedRightNav} shouldShowSidebarContent={true} menuSide="right" />
        </div>
      </Wrapper>
    </animated.div>
  );
};

export default SidebarRightDesktop;
